import React, { useState, useEffect, useCallback } from "react";
import { Link } from 'react-router-dom';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import { useAuth } from '../AuthContext';
import SuccessBanner from './SuccessBanner';
import { useNewsService } from './../services/newsService';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#212121',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: 'white',
  border: '1px solid white',
  fontFamily: 'monospace, serif',
  fontSize: '16px',
}));

const StyledLink = styled(Link)({
  color: '#fff',
  textDecoration: 'none',
  fontWeight: 'bold',
  '&:hover': {
    textDecoration: 'underline',
  },
  '&:focus': {
    outline: 'none',
  },
});

const NewsContainer = styled(Paper)(({ theme }) => ({
  backgroundColor: '#212121',
  padding: theme.spacing(2),
  color: 'white',
  border: '1px solid white',
  fontFamily: 'monospace, serif',
  fontSize: '16px',
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
}));

const NewsItem = styled(Paper)(({ theme }) => ({
  backgroundColor: '#383838',
  padding: theme.spacing(1),
  color: 'white',
  border: '1px solid grey',
  fontFamily: 'monospace, serif',
  fontSize: '16px',
  textAlign: 'center',
  height: 'auto',
  marginBottom: '16px',
  '&:last-child': {
    marginBottom: 0,
  },
}));

const NewsTitle = styled('div')({
  fontSize: '18px',
  marginBottom: '8px',
});

const NewsContent = styled('div')({
  fontSize: '14px',
  marginBottom: '8px',
});

const NewsDate = styled('span')({
  fontSize: '12px',
  color: '#aaa',
});

const PandaImage = styled('img')({
  width: '80vw',
  maxWidth: '800px',
  height: 'auto',
});

const Home = () => {
  const { user, isLoading } = useAuth();
  const [showLoginSuccess, setShowLoginSuccess] = useState(false);
  const [news, setNews] = useState([]);
  const newsService = useNewsService();

  const hideLoginSuccess = useCallback(() => {
    setShowLoginSuccess(false);
    sessionStorage.removeItem('loginSuccess');
  }, []);

  useEffect(() => {
    const loginSuccess = sessionStorage.getItem('loginSuccess');
    if (loginSuccess === 'true') {
      setShowLoginSuccess(true);
      
      const timer = setTimeout(hideLoginSuccess, 5000);

      return () => clearTimeout(timer);
    }
  }, [hideLoginSuccess]);

  useEffect(() => {
    if (showLoginSuccess) {
      console.log('Banner shown at:', new Date().toISOString());
      setTimeout(() => {
        console.log('5 seconds passed at:', new Date().toISOString());
        if (showLoginSuccess) {
          console.log('Banner still shown, forcing hide');
          hideLoginSuccess();
        } else {
          console.log('Banner already hidden');
        }
      }, 5000);
    }
  }, [showLoginSuccess, hideLoginSuccess]);
  
  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await newsService.getVisibleNews();
        setNews(response.data);
      } catch (error) {
        console.error("Error fetching news:", error);
      }
    };

    fetchNews();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Box sx={{ flexGrow: 1, backgroundColor: '#121212', color: '#fff', padding: '20px', minHeight: '80vh' }}>
      {showLoginSuccess && <SuccessBanner message="Erfolgreich angemeldet!" />}
      <Grid
        container
        spacing={2}
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12}>
          <Item>
            <div style={{ fontSize: '20px', fontWeight: 'bold', letterSpacing: '0.05em' }}>
              Willkommen bei <br/>unserem Schachprojekt!
            </div>
          </Item>
        </Grid>
        {!user && (
          <Grid item xs={12}>
            <Item>
              <div style={{ fontSize: '14px', letterSpacing: '0.05em' }}>
                Melde dich <StyledLink to="/LogIn">hier</StyledLink> an <br/>
                oder <StyledLink to="/Register">registriere</StyledLink> dich.
              </div>
            </Item>
          </Grid>
        )}
        {news.length > 0 && (
          <Grid item xs={12}>
            <NewsContainer>
              <div style={{ fontSize: '20px', marginBottom: '16px' }}>Neuigkeiten</div>
              {news.map((item) => (
                <NewsItem key={item.id}>
                  <NewsTitle>{item.title}</NewsTitle>
                  <NewsContent>{item.content}</NewsContent>
                  <NewsDate>Veröffentlicht am: {new Date(item.created_at).toLocaleDateString()}</NewsDate>
                </NewsItem>
              ))}
            </NewsContainer>
          </Grid>
        )}
        <Grid item xs={12}>
          <Item>
            <PandaImage src="/panda.png" alt="Panda" />
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Home;