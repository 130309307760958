import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAdminDeckService } from './../services/adminDeckService';
import { useDeckService } from './../services/deckService';
import {
  Container,
  Typography,
  List,
  ListItem,
  ListItemText,
  Button,
  CircularProgress,
  Box,
} from '@mui/material';

const AdminDeckSelector = () => {
  const navigate = useNavigate();
  const adminDeckService = useAdminDeckService();
  const deckService = useDeckService();
  const [adminDecks, setAdminDecks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchAdminDecks();
  }, []);

  const fetchAdminDecks = async () => {
    try {
      setLoading(true);
      const response = await adminDeckService.getAdminDecks();
      setAdminDecks(response.data);
    } catch (error) {
      console.error('Error fetching admin decks:', error);
      setError(error.response?.data?.message || error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSelectDeck = async (deck) => {
    try {
      const cardIds = deck.cards.map(card => card.id);
      await deckService.createDeck(cardIds, deck.name);
      navigate('/meindeck');
    } catch (error) {
      console.error('Error creating deck:', error);
      setError(error.response?.data?.message || error.message);
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Container>
        <Typography variant="h6" color="error">Error: {error}</Typography>
      </Container>
    );
  }

  return (
    <Container>
      <Typography variant="h4" gutterBottom>Select Recommended Deck</Typography>
      <List>
        {adminDecks.map((deck) => (
          <ListItem key={deck.id} divider>
            <ListItemText 
              primary={deck.name} 
              secondary={deck.description} 
            />
            <Button onClick={() => handleSelectDeck(deck)}>Select</Button>
          </ListItem>
        ))}
      </List>
      <Box mt={2}>
        <Button onClick={() => navigate('/meindeck')}>Back to My Decks</Button>
      </Box>
    </Container>
  );
};

export default AdminDeckSelector;