import React, { useState, useEffect } from "react";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import { useNewsService } from "./../../services/newsService";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#212121',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  color: 'white',
  border: '1px solid white',
  fontFamily: 'monospace, serif',
  fontSize: '16px',
  textAlign: 'center',
}));

const NewsItem = styled(Paper)(({ theme }) => ({
  backgroundColor: '#383838',
  padding: theme.spacing(1),
  color: 'white',
  border: '1px solid grey',
  fontFamily: 'monospace, serif',
  fontSize: '16px',
  textAlign: 'center',
  marginBottom: theme.spacing(2),
  '&:last-child': {
    marginBottom: 0,
  },
}));

const StyledButton = styled('button')(({ theme }) => ({
  borderRadius: '4px',
  border: '1px solid white',
  backgroundColor: '#121212',
  color: 'white',
  padding: theme.spacing(0.5, 1),
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#4a4a4a',
  },
}));

const StyledInput = styled('input')(({ theme }) => ({
  borderRadius: '4px',
  backgroundColor: '#121212',
  color: 'white',
  border: '1px solid white',
  padding: theme.spacing(0.5),
  '&::placeholder': {
    color: 'rgba(255, 255, 255, 0.7)',
  },
}));

const StyledTextArea = styled('textarea')(({ theme }) => ({
  borderRadius: '4px',
  backgroundColor: '#121212',
  color: 'white',
  border: '1px solid white',
  padding: theme.spacing(0.5),
  '&::placeholder': {
    color: 'rgba(255, 255, 255, 0.7)',
  },
}));

const AdminNews = () => {
    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");
    const [message, setMessage] = useState("");
    const [news, setNews] = useState([]);

    const newsService = useNewsService();

    useEffect(() => {
        fetchNews();
    }, []);

    const fetchNews = async () => {
        try {
            const response = await newsService.getVisibleNews();
            setNews(response.data);
        } catch (error) {
            setMessage("Error fetching news: " + (error.response?.data?.message || error.message));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await newsService.createNews({ title, content });
            setMessage("News post created successfully!");
            setTitle("");
            setContent("");
            fetchNews();
        } catch (error) {
            setMessage("Error creating news post: " + (error.response?.data?.message || error.message));
        }
    };

    const handleToggleVisibility = async (id) => {
        try {
            await newsService.toggleNewsVisibility(id);
            setMessage("News visibility toggled successfully!");
            fetchNews();
        } catch (error) {
            setMessage("Error toggling news visibility: " + (error.response?.data?.message || error.message));
        }
    };

    return (
        <Box sx={{ flexGrow: 1, backgroundColor: '#121212', color: '#fff', padding: '20px', minHeight: '80vh' }}>
            <Grid
                container
                spacing={2}
                direction="column"
                justifyContent="center"
                alignItems="center"
            >
                <Grid item xs={12}>
                    <Item>
                        <div style={{ fontSize: '20px', marginBottom: '16px' }}>Create News</div>
                        {message && <p>{message}</p>}
                        <form onSubmit={handleSubmit}>
                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
                                <label htmlFor="title" style={{ marginRight: '10px', minWidth: '70px', textAlign: 'right' }}>Title:</label>
                                <StyledInput
                                    type="text"
                                    id="title"
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                    required
                                    style={{ flex: 1 }}
                                />
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
                                <label htmlFor="content" style={{ marginRight: '10px', minWidth: '70px', textAlign: 'right' }}>Content:</label>
                                <StyledTextArea
                                    id="content"
                                    value={content}
                                    onChange={(e) => setContent(e.target.value)}
                                    required
                                    style={{ flex: 1 }}
                                />
                            </div>
                            <StyledButton type="submit">Publish</StyledButton>
                        </form>
                    </Item>
                </Grid>
                <Grid item xs={12}>
                    <Item>
                        <div style={{ fontSize: '20px' }}>Manage News</div>
                        {news.map((item) => (
                            <NewsItem key={item.id} style={{ marginTop: '16px' }}>
                                <div style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '8px' }}>{item.title}</div>
                                <div style={{ fontSize: '14px', fontWeight: 'bold', marginBottom: '8px' }}>{item.content}</div>
                                <StyledButton onClick={() => handleToggleVisibility(item.id)}>
                                    {item.is_visible ? "Hide" : "Show"}
                                </StyledButton>
                            </NewsItem>
                        ))}
                    </Item>
                </Grid>
            </Grid>
        </Box>
    );
};

export default AdminNews;