import React, { useState, useEffect } from 'react';

import { useCardService } from '../../services/cardService';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#212121',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: 'white',
  border: '1px solid white',
  fontFamily: 'monospace, serif',
  fontSize: '16px',
}));

const StyledInput = styled('input')({
  padding: '8px',
  border: '1px solid white',
  borderRadius: '4px',
  fontFamily: 'monospace, serif',
  backgroundColor: '#212121',
  color: 'white',
  width: '100%',
  marginBottom: '10px',
});

const StyledButton = styled(Button)(({ theme, variant }) => ({
  width: '100%',
  fontFamily: 'monospace, serif',
  backgroundColor: variant === 'create' ? '#28a745' : '#212121',
  color: 'white',
  border: '1px solid white',
  '&:hover': {
    backgroundColor: variant === 'create' ? '#218838' : '#424242',
  },
}));

const AdminCards = () => {
  const cardService = useCardService();
  const [cards, setCards] = useState([]);
  const [newCard, setNewCard] = useState({ name: '', blocker: '', category: '', effect: '' });
  const [editingCard, setEditingCard] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    fetchCards();
  }, []);

  useEffect(() => {
    const isValid = Object.values(newCard).every(value => value !== '');
    setIsFormValid(isValid);
  }, [newCard]);

  const fetchCards = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await cardService.getCards();
      const data = await response.data;
      setCards(data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const createCard = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    try {
      const response = await cardService.createCard(newCard);
      await fetchCards();
      setNewCard({ name: '', blocker: 0, category: '', effect: '' });
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const updateCard = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await cardService.updateCard(editingCard);
      await fetchCards();
      setEditingCard(null);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const deleteCard = async (id) => {
    if (!window.confirm('Are you sure you want to delete this card?')) return;
    setLoading(true);
    setError(null);
    try {
      const response = cardService.deleteCard(id);
      await fetchCards();
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <CircularProgress />;
  if (error) return <div>Error: {error}</div>;

  return (
    <Box sx={{ flexGrow: 1, width: '100%', padding: 2, backgroundColor: '#121212' }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Item>
            <h3 style={{ color: 'white' }}>Create New Card</h3>
            <form onSubmit={createCard} style={{
              fontFamily: 'monospace, serif',
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
              width: '100%',
              border: '1px solid white',
              padding: '16px',
              borderRadius: '4px',
            }}>
              <StyledInput 
                value={newCard.name} 
                onChange={(e) => setNewCard({...newCard, name: e.target.value})}
                placeholder="Name"
                required
              />
              <StyledInput 
                type="number"
                min="0"
                value={newCard.blocker}
                onChange={(e) => setNewCard({...newCard, blocker: Math.max(0, parseInt(e.target.value) || 0)})}
                placeholder="Blocker"
                required
              />
              <StyledInput 
                value={newCard.category} 
                onChange={(e) => setNewCard({...newCard, category: e.target.value})}
                placeholder="Category"
                required
              />
              <StyledInput 
                value={newCard.effect} 
                onChange={(e) => setNewCard({...newCard, effect: e.target.value})}
                placeholder="Effect"
                required
              />
              <StyledButton 
                type="submit" 
                variant="create"
                disabled={!isFormValid}
              >
                Create Card
              </StyledButton>
            </form>
          </Item>
        </Grid>
        <Grid item xs={12} md={12}>
          <Item>
            <h3 style={{ color: 'white' }}>Existing Cards</h3>
            {cards.length === 0 ? (
              <p>No cards found.</p>
            ) : (
              cards.map(card => (
                <Box key={card.id} sx={{ marginBottom: 2, padding: 1, border: '1px solid white', borderRadius: '4px',}}>
                  {editingCard && editingCard.id === card.id ? (
                    <>
                      <StyledInput 
                        value={editingCard.name} 
                        onChange={(e) => setEditingCard({...editingCard, name: e.target.value})}
                      />
                      <StyledInput 
                        type="number"
                        min="0"
                        value={editingCard.blocker}
                        onChange={(e) => setEditingCard({...editingCard, blocker: Math.max(0, parseInt(e.target.value) || 0)})}
                      />
                      <StyledInput 
                        value={editingCard.category} 
                        onChange={(e) => setEditingCard({...editingCard, category: e.target.value})}
                      />
                      <StyledInput 
                        value={editingCard.effect} 
                        onChange={(e) => setEditingCard({...editingCard, effect: e.target.value})}
                      />
                      <StyledButton onClick={updateCard}>Save</StyledButton>
                      <StyledButton onClick={() => setEditingCard(null)}>Cancel</StyledButton>
                    </>
                  ) : (
                    <>
                      <div>{card.name}</div>
                      <div>Blocker: {card.blocker}</div>
                      <div>{card.category}</div>
                      <div>{card.effect}</div>
                      <StyledButton onClick={() => setEditingCard(card)}>Edit</StyledButton>
                      <StyledButton onClick={() => deleteCard(card.id)}>Delete</StyledButton>
                    </>
                  )}
                </Box>
              ))
            )}
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AdminCards;