import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useDeckService } from './../services/deckService';
import { useAdminDeckService } from './../services/adminDeckService';
import AdminDeckSelector from './AdminDeckSelector';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import EqualizerSharpIcon from '@mui/icons-material/EqualizerSharp';
import CircularProgress from '@mui/material/CircularProgress';
import CancelSharpIcon from '@mui/icons-material/CancelSharp';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import Fade from '@mui/material/Fade';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import Looks3Icon from '@mui/icons-material/Looks3';
import Looks4Icon from '@mui/icons-material/Looks4';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';

const TRANSITION_DURATION = 300;

const StyledDialogTitle = styled(DialogTitle)({
  fontFamily: 'monospace, serif',
  color: 'white',
  backgroundColor: '#212121',
});

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    backgroundColor: '#212121',
    color: 'white',
    border: '1px solid white',
  },
  '& .MuiBackdrop-root': {
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
  },
}));

const StyledDialogContent = styled(DialogContent)({
  fontFamily: 'monospace, serif',
  color: 'white',
});

const Item = styled(Paper)(({ theme, clickable }) => ({
  backgroundColor: '#212121',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: 'white',
  border: '1px solid white',
  fontFamily: 'monospace, serif',
  fontSize: '16px',
  cursor: clickable ? 'pointer' : 'default',
  height: 'auto',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

const TallItem = styled(Item)({
  height: '80px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  position: 'relative',
  padding: '16px',
  border: '1px solid white',
});

const TallItemContent = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  height: '100%',
});

const CardTitle = styled('div')({
  fontWeight: 'bold',
  fontSize: '14px',
});

const CardCategory = styled('div')({
  fontSize: '12px',
});

const DeckName = styled('div')({
  fontWeight: 'bold',
  fontSize: '20px',
  marginBottom: '16px',
  textAlign: 'center',
});

const StatTitle = styled('div')({
  fontWeight: 'bold',
  fontSize: '16px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'sticky',
});

const StatInfo = styled('div')({
  fontSize: '14px',
});


const DeckSelector = styled(Box)({
  display: 'flex',
  justifyContent: 'space-around',
  marginBottom: '20px',
});

const DeckIcon = styled(IconButton)(({ theme, selected }) => ({
  backgroundColor: selected ? theme.palette.primary.main : 'transparent',
  color: selected ? theme.palette.primary.contrastText : theme.palette.text.primary,
  '&:hover': {
    backgroundColor: selected ? theme.palette.primary.dark : theme.palette.action.hover,
  },
}));

const BasicGrid = () => {
  const navigate = useNavigate();
  const deckService = useDeckService();
  const [decks, setDecks] = useState([]);
  const [selectedDeckIndex, setSelectedDeckIndex] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const [createDeckDialogOpen, setCreateDeckDialogOpen] = useState(false);
  const [adminDeckSelectorOpen, setAdminDeckSelectorOpen] = useState(false);
  const adminDeckService = useAdminDeckService();

  useEffect(() => {
    fetchDecks();
  }, []);
  

  const fetchDecks = async () => {
    try {
      setLoading(true);
      const response = await deckService.getDecks();
      setDecks(response.data);
      setSelectedDeckIndex(response.data.length > 0 ? 0 : null);
    } catch (error) {
      console.error('Error fetching decks:', error);
      setError(error.response?.data?.message || error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDeckSelect = (index) => {
    setSelectedDeckIndex(index);
  };

  const handleCreateDeckClick = () => {
    setCreateDeckDialogOpen(true);
  };

  const handleCloseCreateDeckDialog = () => {
    setCreateDeckDialogOpen(false);
  };

  const handleCreateNewDeck = () => {
    setCreateDeckDialogOpen(false);
    navigate('/deckbuilding');
  };

  const handleSelectRecommendedDeck = () => {
    setCreateDeckDialogOpen(false);
    navigate('/admin-deck-selector');
  };


  const handleAdminDeckSelect = async (selectedAdminDeck) => {
    try {
      const response = await deckService.createDeck(selectedAdminDeck.name, selectedAdminDeck.card_ids);
      await fetchDecks();
      setAdminDeckSelectorOpen(false);
    } catch (error) {
      console.error('Error creating deck from admin deck:', error);
      setError(error.response?.data?.message || error.message);
    }
  };

  const handleEditDeck = () => {
    navigate('/deckbuilding', { state: { deck: decks[selectedDeckIndex] } });
  };

  const handleDeleteDeck = async () => {
    if (window.confirm('Are you sure you want to delete this deck?')) {
      try {
        await deckService.deleteDeck(decks[selectedDeckIndex].id);
        await fetchDecks();
      } catch (error) {
        console.error('Error deleting deck:', error);
        setError(error.response?.data?.message || error.message);
      }
    }
  };

  const handleInfoClick = (card) => {
    setSelectedCard(card);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setTimeout(() => setSelectedCard(null), TRANSITION_DURATION);
  };

  const calculateStats = (cards) => {
    if (!cards || cards.length === 0) return { avgBlocker: 0, dominantCategory: 'N/A' };

    const totalBlocker = cards.reduce((sum, card) => sum + card.blocker, 0);
    const avgBlocker = (totalBlocker / cards.length).toFixed(2);

    const categoryCount = cards.reduce((acc, card) => {
      acc[card.category] = (acc[card.category] || 0) + 1;
      return acc;
    }, {});

    const dominantCategory = Object.entries(categoryCount).reduce((a, b) => a[1] > b[1] ? a : b)[0];

    return { avgBlocker, dominantCategory };
  };

  const renderCards = (cards) => {
    return (
      <Grid container spacing={2}>
        {cards.map((card, index) => (
          <Grid item xs={6} key={index}>
            <TallItem onClick={() => handleInfoClick(card)}>
              <TallItemContent>
                <CardTitle>{card.name}</CardTitle>
                <CardCategory>{card.category.charAt(0).toUpperCase() + card.category.slice(1).toLowerCase()}</CardCategory>
              </TallItemContent>
            </TallItem>
          </Grid>
        ))}
      </Grid>
    );
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const selectedDeck = selectedDeckIndex !== null ? decks[selectedDeckIndex] : null;

  return (
    <Box sx={{ flexGrow: 1, width: '100%', paddingBottom: '60px' }}>

      <DeckSelector>
        {[LooksOneIcon, LooksTwoIcon, Looks3Icon, Looks4Icon].map((Icon, index) => (
          <DeckIcon
            key={index}
            selected={selectedDeckIndex === index}
            onClick={() => handleDeckSelect(index)}
            disabled={index >= decks.length}
          >
            <Icon />
          </DeckIcon>
        ))}
        {decks.length < 4 && (
          <IconButton onClick={handleCreateDeckClick}>
            <AddIcon />
          </IconButton>
        )}
      </DeckSelector>

      {selectedDeck ? (
        <>
          <DeckName>{selectedDeck.name}</DeckName>
          <Box sx={{ display: 'flex', justifyContent: 'space-around', marginBottom: '20px' }}>
            <Button startIcon={<EditIcon />} onClick={handleEditDeck}>Edit</Button>
            <Button startIcon={<DeleteIcon />} onClick={handleDeleteDeck}>Delete</Button>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Item clickable>
                <StatTitle>
                  Statistiken <EqualizerSharpIcon sx={{ marginLeft: 1 }} />
                </StatTitle>
                <StatInfo>
                  &#8709; Blocker: {calculateStats(selectedDeck.cards).avgBlocker}
                </StatInfo>
                <StatInfo>
                  Deckfokus: {calculateStats(selectedDeck.cards).dominantCategory}
                </StatInfo>
              </Item>
            </Grid>
            <Divider sx={{ backgroundColor: 'white', height: '1px', width: '95%', margin: '8px auto 16px auto', alignItems: 'center' }} />
          </Grid>

          {renderCards(selectedDeck.cards)}
        </>
      ) : (
        <Box sx={{ textAlign: 'center', marginTop: '20px' }}>
          <p>No deck selected. Create a new deck to get started!</p>
          <Button startIcon={<AddIcon />} onClick={handleCreateDeckClick}>Create New Deck</Button>
        </Box>
      )}
      <Dialog open={createDeckDialogOpen} onClose={handleCloseCreateDeckDialog}>
        <DialogTitle>Create New Deck</DialogTitle>
        <DialogContent>
          <Button onClick={handleCreateNewDeck}>Create Custom Deck</Button>
          <Button onClick={handleSelectRecommendedDeck}>Select Recommended Deck</Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCreateDeckDialog}>Cancel</Button>
        </DialogActions>
      </Dialog>

      {adminDeckSelectorOpen && (
      <AdminDeckSelector
        open={adminDeckSelectorOpen}
        onClose={() => setAdminDeckSelectorOpen(false)}
        onSelect={handleAdminDeckSelect}
      />)}

      <StyledDialog 
        open={dialogOpen} 
        onClose={handleCloseDialog}
        TransitionComponent={Fade}
        TransitionProps={{ timeout: TRANSITION_DURATION }}
      >
        <Fade in={dialogOpen} timeout={TRANSITION_DURATION}>
          <div>
            <StyledDialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <span>Karteninformation</span>
              <IconButton
                edge="end"
                color="inherit"
                onClick={handleCloseDialog}
                aria-label="close"
              >
                <CancelSharpIcon />
              </IconButton>
            </StyledDialogTitle>
            <StyledDialogContent>
              {selectedCard && (
                <>
                  <div><strong>Effekt:</strong> {selectedCard.effect}</div>
                  <div><strong>Blockierte Züge:</strong> {selectedCard.blocker}</div>
                </>
              )}
            </StyledDialogContent>
          </div>
        </Fade>
      </StyledDialog>
    </Box>
  );
};

const MeinDeck = () => (
  <div style={{ 
    backgroundColor: '#121212', 
    color: '#fff', 
    padding: 20, 
    minHeight: '100vh',
    overflowY: 'auto',
    overflowX: 'hidden'
  }}>
    <BasicGrid />
  </div>
);

export default MeinDeck;
