import React, { useState, useEffect } from "react";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import { useUserService } from "./../../services/userService";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#212121',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  color: 'white',
  border: '1px solid white',
  fontFamily: 'monospace, serif',
  fontSize: '16px',
  textAlign: 'center',
}));

const UserItem = styled(Paper)(({ theme }) => ({
  backgroundColor: '#383838',
  padding: theme.spacing(1),
  color: 'white',
  border: '1px solid grey',
  fontFamily: 'monospace, serif',
  fontSize: '16px',
  textAlign: 'center',
  marginBottom: theme.spacing(2),
  '&:last-child': {
    marginBottom: 0,
  },
}));

const StyledButton = styled('button')(({ theme }) => ({
  borderRadius: '4px',
  border: '1px solid white', // Updated to normal CSS border
  backgroundColor: '#121212',
  color: 'white',
  margin: theme.spacing(0.5),
  padding: theme.spacing(0.5, 1),
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#383838',
  },
}));

const StyledInput = styled('input')(({ theme }) => ({
  borderRadius: '4px',
  backgroundColor: '#121212',
  color: 'white', // Updated text color to white
  border: '1px solid white',
  padding: theme.spacing(0.5),
  margin: theme.spacing(0.5),
  '&::placeholder': {
    color: 'rgba(255, 255, 255, 0.7)', // Added placeholder color for better visibility
  },
}));

const AdminUsers = () => {
    const [users, setUsers] = useState([]);
    const [message, setMessage] = useState('');
    const [passwords, setPasswords] = useState({});
    const userService = useUserService();

    useEffect(() => {
        fetchUsers();
    }, []);

    const fetchUsers = async () => {
        try {
            const response = await userService.getUsers();
            setUsers(response.data);
            const initialPasswords = {};
            response.data.forEach(user => {
                initialPasswords[user.id] = '';
            });
            setPasswords(initialPasswords);
        } catch (error) {
            setMessage('Error fetching users: ' + (error.response?.data?.message || error.message));
        }
    };

    const handleDeleteUser = async (id) => {
        if (window.confirm('Are you sure you want to delete this user?')) {
            try {
                await userService.deleteUser(id);
                setMessage('User deleted successfully');
                fetchUsers();
            } catch (error) {
                setMessage('Error deleting user: ' + (error.response?.data?.message || error.message));
            }
        }
    };

    const handleSetPassword = async (id) => {
        try {
            await userService.setUserPassword(id, passwords[id]);
            setMessage('Password updated successfully');
            setPasswords(prev => ({ ...prev, [id]: '' }));
        } catch (error) {
            setMessage('Error updating password: ' + (error.response?.data?.message || error.message));
        }
    };

    const handleToggleAdmin = async (id) => {
        try {
            await userService.toggleAdminStatus(id);
            setMessage('Admin status toggled successfully');
            fetchUsers();
        } catch (error) {
            setMessage('Error toggling admin status: ' + (error.response?.data?.message || error.message));
        }
    };

    const handlePasswordChange = (id, value) => {
        setPasswords(prev => ({ ...prev, [id]: value }));
    };

    return (
        <Box sx={{ flexGrow: 1, backgroundColor: '#121212', color: '#fff', padding: '20px', minHeight: '80vh' }}>
            <Grid
                container
                spacing={2}
                direction="column"
                justifyContent="center"
                alignItems="center"
            >
                <Grid item xs={12}>
                    <Item>
                        <div style={{ fontSize: '20px', marginBottom: '16px' }}>Manage Users</div>
                        {message && <p>{message}</p>}
                        {users.map(user => (
                            <UserItem key={user.id}>
                                <div style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '8px' }}>{user.username}</div>
                                <div style={{ fontSize: '14px', marginBottom: '8px' }}>Admin: {user.is_admin ? 'Yes' : 'No'}</div>
                                <StyledButton onClick={() => handleDeleteUser(user.id)}>Delete</StyledButton>
                                <StyledButton onClick={() => handleToggleAdmin(user.id)}>
                                    {user.is_admin ? 'Remove Admin' : 'Make Admin'}
                                </StyledButton>
                                <StyledInput 
                                    type="password" 
                                    placeholder="New Password" 
                                    value={passwords[user.id]} 
                                    onChange={(e) => handlePasswordChange(user.id, e.target.value)}
                                />
                                <StyledButton onClick={() => handleSetPassword(user.id)}>Set New Password</StyledButton>
                            </UserItem>
                        ))}
                    </Item>
                </Grid>
            </Grid>
        </Box>
    );
};

export default AdminUsers;