import React, { useState } from "react";
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import Collapse from '@mui/material/Collapse';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#212121',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: 'white',
  border: '1px solid white',
  fontFamily: 'monospace, serif',
  fontSize: '16px',
}));

const StyledLink = styled(Link)({
  color: '#fff',
  textDecoration: 'none',
  fontWeight: 'bold',
  '&:hover': {
    textDecoration: 'underline',
  },
  '&:focus': {
    outline: 'none',
  },
});

const SubLink = styled(StyledLink)({
  fontSize: '0.9em',
  paddingLeft: '20px',
});

const AdminPortal = () => {
  const [deckManagementOpen, setDeckManagementOpen] = useState(false);

  const toggleDeckManagement = () => {
    setDeckManagementOpen(!deckManagementOpen);
  };

  return (
    <Box sx={{ flexGrow: 1, backgroundColor: '#121212', color: '#fff', padding: '20px', minHeight: '80vh' }}>
      <Grid
        container
        spacing={2}
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12}>
          <Item>
            <div style={{ fontSize: '2em', letterSpacing: '0.05em', lineHeight: '1.5', fontWeight: 'bold' }}>
              Admin Portal
            </div>
          </Item>
        </Grid>
        <Grid item xs={12}>
          <Item>
            <div style={{ fontSize: '1.5em', letterSpacing: '0.05em', lineHeight: '2' }}>
              <StyledLink to="/admin/users">
                Benutzer
              </StyledLink><br/>
              <StyledLink to="/admin/cards">
                Kartenmanagement
              </StyledLink><br/>
              <div onClick={toggleDeckManagement} style={{ cursor: 'pointer' }}>
                Deckmanagement {deckManagementOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </div>
              <Collapse in={deckManagementOpen}>
                <SubLink to="/admin/decks/create">
                  Neues Deck erstellen
                </SubLink><br/>
                <SubLink to="/admin/decks">
                  Existierende Decks bearbeiten
                </SubLink>
              </Collapse>
              <StyledLink to="/admin/gamehistory">
                Spielhistorie
              </StyledLink><br/>
              <StyledLink to="/admin/rules">
                Regeln
              </StyledLink><br/>
              <StyledLink to="/admin/news">
                News
              </StyledLink>
            </div>
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AdminPortal;