import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '../AuthContext';
import { useAuthService } from '../services/authService';

import styles from './LogIn.module.css';


const BlockCube = ({ children }) => (
  <>
    <div className={styles["bg-top"]}>
      <div className={styles["bg-inner"]}></div>
    </div>
    <div className={styles["bg-right"]}>
      <div className={styles["bg-inner"]}></div>
    </div>
    <div className={styles["bg"]}>
      <div className={styles["bg-inner"]}></div>
    </div>
    {children}
  </>
);

const InputField = ({ name, type, placeholder, value, onChange }) => (
  <div className={`${styles.control} ${styles["block-cube"]} ${styles["block-input"]}`}>
    <input name={name} type={type} placeholder={placeholder} value={value} onChange={onChange} />
    <BlockCube />
  </div>
);

const LogIn = () => {
  const authService = useAuthService();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { login } = useAuth();

  const handleLogin = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setErrorMessage('');
    try {
      const response = await authService.login(username, password);
      login(username, response.data.is_admin);
      sessionStorage.setItem('loginSuccess', 'true');
      navigate('/home');
    } catch (error) {
      if (error.response) {
        setErrorMessage(error.response.data.message || 'Login fehlgeschlagen');
      } else if (error.request) {
        setErrorMessage('Keine Antwort vom Server. Bitte überprüfen Sie Ihre Internetverbindung.');
      } else {
        setErrorMessage('Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <div className={styles.form}>
        <div className={styles.control}>
          <h1>Anmelden</h1>
        </div>
        <form onSubmit={handleLogin}>
          <InputField
            name="username"
            type="text"
            placeholder="Benutzername"
            value={username}
            onChange={(e) => {
              setUsername(e.target.value);
              setErrorMessage('');
            }}
          />
          <InputField
            name="password"
            type="password"
            placeholder="Passwort"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
              setErrorMessage('');
            }}
          />
          <button
            type='submit'
            className={`${styles.btn} ${styles["block-cube"]} ${styles["block-cube-hover"]}`}
            disabled={isLoading}
          >
            <BlockCube>
              <span className={styles.text}>{isLoading ? 'Anmeldung...' : 'Jetzt Anmelden'}</span>
            </BlockCube>
          </button>
        </form>
        {errorMessage && <p className={styles.error}>{errorMessage}</p>}
        <div className={styles.registerLink}>
          Noch kein Konto? <a href="/register">Registrieren</a>
        </div>
      </div>
    </div>
  );
};

export default LogIn;