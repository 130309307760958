import React, { useState, useEffect } from "react";
import { styled } from '@mui/material/styles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CircularProgress from '@mui/material/CircularProgress';
import { useRuleService } from '../services/ruleService';

const CustomAccordionSummary = styled(AccordionSummary)(({ theme, expanded }) => ({
  backgroundColor: '#212121',
  color: '#fff',
  border: expanded ? '1px solid' : '1px solid white',
  borderImage: expanded ? 'linear-gradient(90deg, rgb(39, 34, 141) 0%, rgba(52,9,121,1) 37%, rgba(0,212,255,1) 94%)' : '',
  borderImageSlice: expanded ? 1 : 0,
  fontSize: expanded ? '20px' : '18px',
}));

const CustomAccordionDetails = styled(AccordionDetails)({
  backgroundColor: '#212121',
  color: '#fff',
  border: '1px solid',
  borderImage: 'linear-gradient(90deg, rgb(39, 34, 141) 0%, rgba(52,9,121,1) 37%, rgba(0,212,255,1) 94%)',
  borderImageSlice: 1,
  textAlign: 'left',
});

const Regeln = () => {
  const [expanded, setExpanded] = useState(false);
  const [rules, setRules] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const ruleService = useRuleService();

  useEffect(() => {
    fetchRules();
  }, []);

  const fetchRules = async () => {
    try {
      const response = await ruleService.getRules();
      setRules(response.data);
      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  if (loading) return <CircularProgress />;
  if (error) return <div>Error: {error}</div>;

  return (
    <div style={{ backgroundColor: '#121212', color: '#fff', minHeight: '100vh', padding: '20px' }}>
      {rules.map((rule, index) => (
        <Accordion
          key={rule.id}
          expanded={expanded === index}
          onChange={handleChange(index)}
        >
          <CustomAccordionSummary
            expandIcon={<ExpandMoreIcon style={{ color: '#fff' }} />}
            aria-controls={`panel${index + 1}-content`}
            id={`panel${index + 1}-header`}
            expanded={expanded === index}
          >
            {rule.title}
          </CustomAccordionSummary>
          <CustomAccordionDetails>
            {rule.text.split('\n').map((line, i) => (
              <React.Fragment key={i}>
                {line}
                <br />
              </React.Fragment>
            ))}
          </CustomAccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}

export default Regeln;