import React from "react";
import { Link } from 'react-router-dom';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import AdjustSharpIcon from '@mui/icons-material/AdjustSharp';
import ReplaySharp from "@mui/icons-material/ReplaySharp";
import NavigateNextSharpIcon from '@mui/icons-material/NavigateNextSharp';


const Item = styled(Paper)(({ theme, clickable }) => ({
  backgroundColor: '#212121',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: 'white',
  border: '1px solid white',
  fontFamily: 'monospace, serif',
  fontSize: '16px',
  cursor: clickable ? 'pointer' : 'default',
}));

const TallItem = styled(Item)({
  height: 'auto',
  display: 'flex',
  flexDirection: 'column',
  padding: '16px',
});

const CardHeader = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '16px',
});

const CardName = styled('div')({
  fontWeight: 'bold',
  fontSize: '18px',
});

const CardBlocker = styled('div')({
  fontWeight: 'bold',
  fontSize: '18px',
});

const CardEffect = styled('div')({
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  padding: '8px',
});

const CardCategory = styled('div')({
  alignSelf: 'center',
  fontWeight: 'bold',
  marginTop: '12px',
});

const ButtonItem = styled(Item)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '8px 16px',
});

const BasicGrid = () => {
  const cards = [
    {
      id: 1,
      name: 'Card One',
      blocker: '3',
      effect: 'Effect of Card One',
      category: 'Category 1',
    },
    {
      id: 2,
      name: 'Card Two',
      blocker: '4',
      effect: 'Effect of Card Two',
      category: 'Category 2',
    },
    {
      id: 3,
      name: 'Card Three',
      blocker: '5',
      effect: 'Effect of Card Three',
      category: 'Category 3',
    }
  ];

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Item clickable sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <span>Zug machen</span>
            <div>
              <NavigateNextSharpIcon style={{ width: '24px', height: '24px' }} />
            </div>
            <span>Zug: 5</span>
          </Item>
        </Grid>
        {cards.map((card) => (
          <Grid item xs={12} key={card.id}>
            <Link style={{ textDecoration: 'none' }}>
              <TallItem clickable>
                <CardHeader>
                  <CardName>{card.name}</CardName>
                  <CardBlocker>Blocker: {card.blocker}</CardBlocker>
                </CardHeader>
                <CardEffect>{card.effect}</CardEffect>
                <CardCategory>{card.category}</CardCategory>
              </TallItem>
            </Link>
          </Grid>
        ))}
        <Grid item xs={6}>
          <ButtonItem>
            <div>
              <ReplaySharp style={{ width: '24px', height: '24px' }} />
            </div>
            <span>Joker: 2</span>
          </ButtonItem>
        </Grid>
        <Grid item xs={6}>
          <ButtonItem>
            <div>
              <AdjustSharpIcon style={{ width: '24px', height: '24px' }} />
            </div>
            <span>Karten: 15</span>
          </ButtonItem>
        </Grid>
      </Grid>
    </Box>
  );
};

const Testziehen = () => (
  <div style={{ backgroundColor: '#121212', color: '#fff', padding: '20px' }}>
    <BasicGrid />
  </div>
);

export default Testziehen;
