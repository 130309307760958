import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useAdminDeckService } from "../../services/adminDeckService";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CircularProgress from '@mui/material/CircularProgress';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#212121',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: 'white',
  border: '1px solid white',
  fontFamily: 'monospace, serif',
}));

const AdminDeckManager = () => {
  const [decks, setDecks] = useState([]);
  const [loading, setLoading] = useState(true);
  const adminDeckService = useAdminDeckService();
  const navigate = useNavigate();

  useEffect(() => {
    fetchDecks();
  }, []);

  const fetchDecks = async () => {
    try {
      const response = await adminDeckService.getAdminDecks();
      setDecks(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching decks:', error);
      setLoading(false);
    }
  };

  const handleEdit = (deck) => {
    navigate('/admin/decks/create', { state: { editDeck: deck } });
  };

  const handleDelete = async (deckId) => {
    if (window.confirm('Are you sure you want to delete this deck?')) {
      try {
        await adminDeckService.deleteAdminDeck(deckId);
        fetchDecks();
      } catch (error) {
        console.error('Error deleting deck:', error);
      }
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Box sx={{ flexGrow: 1, padding: 3 }}>
      <Grid container spacing={3}>
        {decks.map((deck) => (
          <Grid item xs={12} sm={6} md={4} key={deck.id}>
            <Item>
              <h3>{deck.name}</h3>
              <p>{deck.description}</p>
              <Button
                startIcon={<EditIcon />}
                onClick={() => handleEdit(deck)}
                sx={{ marginRight: 1 }}
              >
                Edit
              </Button>
              <Button
                startIcon={<DeleteIcon />}
                onClick={() => handleDelete(deck.id)}
                color="error"
              >
                Delete
              </Button>
            </Item>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default AdminDeckManager;