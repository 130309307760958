import React, { useState, useEffect } from 'react';
import { useRuleService } from '../../services/ruleService';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#212121',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: 'white',
  border: '1px solid white',
  fontFamily: 'monospace, serif',
  fontSize: '16px',
}));

const StyledTextField = styled(TextField)({
  '& .MuiInputBase-input': {
    color: 'white',
  },
  '& .MuiInputLabel-root': {
    color: 'white',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'white',
    },
    '&:hover fieldset': {
      borderColor: 'white',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white',
    },
  },
  marginBottom: '10px',
});

const StyledButton = styled(Button)(({ theme, variant }) => ({
  width: '100%',
  fontFamily: 'monospace, serif',
  backgroundColor: variant === 'create' ? '#28a745' : '#212121',
  color: 'white',
  border: '1px solid white',
  '&:hover': {
    backgroundColor: variant === 'create' ? '#218838' : '#424242',
  },
}));

const AdminRules = () => {
  const ruleService = useRuleService();
  const [rules, setRules] = useState([]);
  const [newRule, setNewRule] = useState({ title: '', text: '' });
  const [editingRule, setEditingRule] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    fetchRules();
  }, []);

  useEffect(() => {
    const isValid = newRule.title !== '' && newRule.text !== '';
    setIsFormValid(isValid);
  }, [newRule]);

  const fetchRules = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await ruleService.getRules();
      setRules(response.data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const createRule = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    try {
      await ruleService.createRule(newRule);
      await fetchRules();
      setNewRule({ title: '', text: '' });
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const updateRule = async () => {
    setLoading(true);
    setError(null);
    try {
      await ruleService.updateRule(editingRule);
      await fetchRules();
      setEditingRule(null);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const deleteRule = async (id) => {
    if (!window.confirm('Are you sure you want to delete this rule?')) return;
    setLoading(true);
    setError(null);
    try {
      await ruleService.deleteRule(id);
      await fetchRules();
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <CircularProgress />;
  if (error) return <div>Error: {error}</div>;

  return (
    <Box sx={{ flexGrow: 1, width: '100%', padding: 2, backgroundColor: '#121212' }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Item>
            <h3 style={{ color: 'white' }}>Create New Rule</h3>
            <form onSubmit={createRule} style={{
              fontFamily: 'monospace, serif',
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
              width: '100%',
              border: '1px solid white',
              padding: '16px',
              borderRadius: '4px',
            }}>
              <StyledTextField 
                value={newRule.title} 
                onChange={(e) => setNewRule({...newRule, title: e.target.value})}
                label="Title"
                variant="outlined"
                required
              />
              <StyledTextField 
                value={newRule.text} 
                onChange={(e) => setNewRule({...newRule, text: e.target.value})}
                label="Text"
                variant="outlined"
                multiline
                rows={4}
                required
              />
              <StyledButton 
                type="submit" 
                variant="create"
                disabled={!isFormValid}
              >
                Create Rule
              </StyledButton>
            </form>
          </Item>
        </Grid>
        <Grid item xs={12} md={12}>
          <Item>
            <h3 style={{ color: 'white' }}>Existing Rules</h3>
            {rules.length === 0 ? (
              <p>No rules found.</p>
            ) : (
              rules.map(rule => (
                <Box key={rule.id} sx={{ marginBottom: 2, padding: 1, border: '1px solid white', borderRadius: '4px',}}>
                  {editingRule && editingRule.id === rule.id ? (
                    <>
                      <StyledTextField 
                        value={editingRule.title} 
                        onChange={(e) => setEditingRule({...editingRule, title: e.target.value})}
                        label="Title"
                        variant="outlined"
                        fullWidth
                      />
                      <StyledTextField 
                        value={editingRule.text} 
                        onChange={(e) => setEditingRule({...editingRule, text: e.target.value})}
                        label="Text"
                        variant="outlined"
                        multiline
                        rows={4}
                        fullWidth
                      />
                      <StyledButton onClick={updateRule}>Save</StyledButton>
                      <StyledButton onClick={() => setEditingRule(null)}>Cancel</StyledButton>
                    </>
                  ) : (
                    <>
                      <h4>{rule.title}</h4>
                      <p>{rule.text}</p>
                      <StyledButton onClick={() => setEditingRule(rule)}>Edit</StyledButton>
                      <StyledButton onClick={() => deleteRule(rule.id)}>Delete</StyledButton>
                    </>
                  )}
                </Box>
              ))
            )}
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AdminRules;