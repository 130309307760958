import React from 'react';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import Paper from '@mui/material/Paper';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#4caf50',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: 'white',
  border: '1px solid white',
  fontFamily: 'monospace, serif',
  fontSize: '16px',
}));

const SuccessBanner = ({ message }) => {
  return (
    <Box sx={{ flexGrow: 1, top: 0, left: 0, right: 0, zIndex: 9999, paddingBottom: '15px' }}>
      <Grid container justifyContent="center">
        <Grid xs={12}>
          <Item>{message}</Item>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SuccessBanner;