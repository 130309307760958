import { Routes, Route, Navigate } from 'react-router-dom';

// public routes
import About from './components/About';
import Home from './components/Home';
import Register from './components/Register';
import LogIn from './components/LogIn';
import Regeln from './components/Regeln';
import Menu from './components/Menu';

// private routes
import PrivateRoute from './PrivateRoute';
import Spielen from './components/Spielen';
import Deckbuilding from './components/Deckbuilding';
import MeinDeck from './components/MeinDeck';
import Testziehen from './components/Testziehen';
import AdminDeckSelector from './components/AdminDeckSelector';

// admin routes
import AdminRoute from './AdminRoute';
import AdminPortal from './components/admin/AdminPortal';
import AdminUsers from './components/admin/AdminUsers';
import AdminCards from './components/admin/AdminCards';
import AdminDecksManager from './components/admin/AdminDecksManager';
import AdminDecksCreate from './components/admin/AdminDecksCreate';
import AdminGameHistory from './components/admin/AdminGameHistory';
import AdminNews from './components/admin/AdminNews';
import AdminRules from './components/admin/AdminRules';

// external links
import ExternalLink from './services/ExternalLink';

import { AuthProvider } from './AuthContext';

import './App.css';


function App() {
  return (
    <AuthProvider>
      <div className="App">
        <Menu/>
        <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="/about" element={<About/>} />
          <Route path="/login" element={<LogIn/>} />
          <Route path="/register" element={<Register/>} />
          <Route path="/home" element={<Home/>} />
          <Route path="/spielen" element={<PrivateRoute><Spielen/></PrivateRoute>} />
          <Route path="/deckbuilding" element={<PrivateRoute><Deckbuilding/></PrivateRoute>} />
          <Route path="/meindeck" element={<PrivateRoute><MeinDeck/></PrivateRoute>} />
          <Route path="/testziehen" element={<PrivateRoute><Testziehen/></PrivateRoute>} />
          <Route path="/admin-deck-selector" element={<AdminDeckSelector />} />
          <Route path="/regeln" element={<Regeln/>} />
          <Route path="/admin" element={<AdminRoute><AdminPortal/></AdminRoute>} />
          <Route path="/admin/users" element={<AdminRoute><AdminUsers/></AdminRoute>} />
          <Route path="/admin/cards" element={<AdminRoute><AdminCards/></AdminRoute>} />
          <Route path="/admin/decks" element={<AdminRoute><AdminDecksManager/></AdminRoute>} />
          <Route path="/admin/decks/create" element={<AdminRoute><AdminDecksCreate/></AdminRoute>} />
          <Route path="/admin/news" element={<AdminRoute><AdminNews/></AdminRoute>} />
          <Route path="/admin/rules" element={<AdminRoute><AdminRules/></AdminRoute>} />
          <Route path="/admin/gamehistory" element={<AdminRoute><AdminGameHistory/></AdminRoute>} />
          <Route path="/external-link" element={<ExternalLink />} />
          <Route path="*" element={<Navigate to="/home" replace />} />
        </Routes>
      </div>
    </AuthProvider>
  );
}

export default App;