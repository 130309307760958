import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { useAuthService } from '../services/authService';

import styles from './LogIn.module.css';

// Components
const BlockCube = ({ children }) => (
  <>
    <div className={styles["bg-top"]}>
      <div className={styles["bg-inner"]}></div>
    </div>
    <div className={styles["bg-right"]}>
      <div className={styles["bg-inner"]}></div>
    </div>
    <div className={styles["bg"]}>
      <div className={styles["bg-inner"]}></div>
    </div>
    {children}
  </>
);

const InputField = ({ name, type, placeholder, value, onChange }) => (
  <div className={`${styles.control} ${styles["block-cube"]} ${styles["block-input"]}`}>
    <input name={name} type={type} placeholder={placeholder} value={value} onChange={onChange} />
    <BlockCube />
  </div>
);

const Register = () => {
  const authService = useAuthService();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate();

  const handleRegister = async (event) => {
    event.preventDefault();
    try {
      await authService.register(username, password);
      setSuccessMessage('Registrierung erfolgreich! Weiter zum LogIn...');
      setTimeout(() => {
        navigate('/login');
      }, 2000);
    } catch (error) {
      setErrorMessage(error.response?.data?.message || 'Registrierung fehlgeschlagen');
    }
  };

  return (
    <div>
      <div className={styles.form}>
        <div className={styles.control}>
          <h1>Registrieren</h1>
        </div>
        <InputField
          name="username"
          type="text"
          placeholder="Benutzername"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <InputField
          name="password"
          type="password"
          placeholder="Passwort"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button
          onClick={handleRegister}
          className={`${styles.btn} ${styles["block-cube"]} ${styles["block-cube-hover"]}`}
        >
          <BlockCube>
            <span className={styles.text}>Jetzt Registrieren</span>
          </BlockCube>
        </button>
        {errorMessage && <p className={styles.error}>{errorMessage}</p>}
        {successMessage && <p className={styles.success}>{successMessage}</p>}
        <div className={styles.registerLink}>
          Schon registriert? <a href="/login">Anmelden</a>
        </div>
      </div>
    </div>
  );
};

export default Register;