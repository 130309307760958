import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Typography, Divider } from '@mui/material';
import GitHubIcon from '@mui/icons-material/GitHub';

const About = () => {
  const commonStyles = {
    backgroundColor: '#212121',
    color: 'white',
    fontFamily: 'monospace',
  };

  const linkStyle = {
    color: 'white',
    textDecoration: 'underline',
    '&:hover': {
      color: 'rgba(255, 255, 255, 0.7)',
    },
  };

  return (
    <Box sx={{
      ...commonStyles,
      minHeight: '100',
      padding: '16px',
    }}>
      <Typography variant="h6" component="h2" gutterBottom sx={{ mt: 3 }}>
        Was ist Facettenschach?
      </Typography>
      <Typography paragraph>
        Facettenschach ist ein innovatives Schachvariante, die durch den Einbau von Spezialzügen und Effekten eine neue Spielerfahrung bietet.
      </Typography>

      <Typography variant="h6" component="h2" gutterBottom sx={{ mt: 3 }}>
        Über diese Plattform
      </Typography>
      <Typography paragraph>
        Diese Online-Plattform wurde entwickelt, um persönliche Decks zu erstellen und zu verwalten. Anschließend kann damit gespielen werden.
      </Typography>

      <Divider sx={{ my: 2, backgroundColor: 'white' }} />

      <Typography variant="h6" component="h2" gutterBottom sx={{ mt: 3 }}>
        Erfinder des Spiels
      </Typography>
      <Typography paragraph>
        Facettenschach wurde von Felix Gappel erfunden.
      </Typography>

      <Typography variant="h6" component="h2" gutterBottom sx={{ mt: 3 }}>
        Entwickler
      </Typography>
      <Typography component="div">
        Diese Plattform wurde entwickelt von:
        <ul style={{ listStyleType: 'none', padding: 0 }}>
          <li>
            py-bay - 
            <Link to="/external-link" state={{ url: 'https://github.com/py-bay' }} style={{ ...linkStyle, display: 'inline-flex', alignItems: 'center', marginLeft: '5px' }}>
              <GitHubIcon sx={{ fontSize: 20, marginRight: '5px' }} />
              GitHub
            </Link>
          </li>
          <li>
            Ellith - 
            <Link to="/external-link" state={{ url: 'https://github.com/ellith' }} style={{ ...linkStyle, display: 'inline-flex', alignItems: 'center', marginLeft: '5px' }}>
              <GitHubIcon sx={{ fontSize: 20, marginRight: '5px' }} />
              GitHub
            </Link>
          </li>
        </ul>
      </Typography>

      <Divider sx={{ my: 2, backgroundColor: 'white' }} />


      {/* Include this if this is open-source*/}
{/*
      <Typography variant="h6" component="h2" gutterBottom sx={{ mt: 3 }}>
        Open Source
      </Typography>
      <Typography paragraph>
        Facettenschach ist ein Open-Source-Projekt. Sie können den Quellcode auf{' '}
        <Link to="/external-link" state={{ url: '[GitHub-Repository-URL]' }} style={{ ...linkStyle, display: 'inline-flex', alignItems: 'center' }}>
          <GitHubIcon sx={{ fontSize: 20, marginRight: '5px' }} />
          GitHub
        </Link> einsehen.
      </Typography>
*/}
      <Typography variant="h6" component="h2" gutterBottom sx={{ mt: 3 }}>
        Kontakt
      </Typography>
      <Typography paragraph>
        Für Fragen, Anregungen oder Feedback kontaktieren Sie uns bitte unter:{' '}
        <a href="mailto:[contact-email]" style={linkStyle}>flashflexx965@gmail.com</a>
      </Typography>
    </Box>
  );
};

export default About;