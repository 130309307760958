import { useApiClient } from './apiClient';

export const useCardService = () => {
  const apiClient = useApiClient();
  
  return {
    getCards: () => apiClient.get('api/cards'),
    createCard: (newCard) => apiClient.post('api/cards/card', JSON.stringify(newCard)),
    updateCard: (editingCard) => apiClient.put(`api/cards/id:${editingCard.id}`, JSON.stringify(editingCard)),
    deleteCard: (CardId) => apiClient.delete(`api/cards/id:${CardId}`)
};
};