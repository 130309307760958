import { useApiClient } from './apiClient';

export const useNewsService = () => {
  const apiClient = useApiClient();

  return {
    getVisibleNews: () => apiClient.get('api/news'),
    createNews: (newsPost) => apiClient.post('api/news', JSON.stringify(newsPost)),
    toggleNewsVisibility: (id) => apiClient.patch(`api/news/${id}/toggle-visibility`),
  };
};