import React, { createContext, useState, useEffect, useContext, useRef } from 'react';

import { useAuthService } from './services/authService';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const authServiceRef = useRef(null);
  const authService = useAuthService(); // Call hook unconditionally

  useEffect(() => {
    // Initialize authServiceRef only once
    if (authServiceRef.current === null) {
      authServiceRef.current = authService;
    }
  }, [authService]);

  useEffect(() => {
    const checkAuth = async () => {
      if (!authServiceRef.current) return; // Ensure authService is initialized
      try {
        const response = await authServiceRef.current.checkAuth();
        setUser(response.data.user, response.data.user.is_admin);
      } catch (error) {
        setUser(null);
      } finally {
        setLoading(false);
      }
    };
    checkAuth();
  }, []);

  const login = (username, is_admin) => {
    setUser({ username, is_admin });
  };

  const logout = async () => {
    if (!authServiceRef.current) return;
    try {
      await authServiceRef.current.logout();
    } finally {
      setUser(null);
    }
  };

  return (
    <AuthContext.Provider value={{ user, login, logout, loading }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);