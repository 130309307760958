import { useApiClient } from './apiClient';

export const useAdminDeckService = () => {
  const apiClient = useApiClient();

  return {
    createAdminDeck: (name, description, cardIds) =>
      apiClient.post('api/admin_deck', { name, description, card_ids: cardIds }),
   
    getAdminDecks: () =>
      apiClient.get('api/admin_deck'),
   
    updateAdminDeck: (deckId, name, description, cardIds) =>
      apiClient.put(`api/admin_deck/${deckId}`, { name, description, card_ids: cardIds }),

    deleteAdminDeck: (deckId) =>
      apiClient.delete(`api/admin_deck/${deckId}`),
  };
};