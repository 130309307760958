import { useApiClient } from './apiClient';

export const useRuleService = () => {
  const apiClient = useApiClient();
 
  return {
    getRules: () => apiClient.get('api/rules'),
    createRule: (newRule) => apiClient.post('api/rules', JSON.stringify(newRule)),
    updateRule: (editingRule) => apiClient.put(`api/rules/${editingRule.id}`, JSON.stringify(editingRule)),
    deleteRule: (ruleId) => apiClient.delete(`api/rules/${ruleId}`),
    getRule: (ruleId) => apiClient.get(`api/rules/${ruleId}`)
  };
};