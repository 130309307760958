import { useApiClient } from './apiClient';

export const useDeckService = () => {
  const apiClient = useApiClient();
   
  return {
    getDecks: () => apiClient.get('/api/user/decks'),

    getDeck: (deckId) => apiClient.get(`/api/user/deck/${deckId}`),
    
    createDeck: (cardIds, deckName) => apiClient.post('/api/user/deck', { card_ids: cardIds, deck_name: deckName }),

    updateDeck: (deckId, cardIds, deckName) => apiClient.put(`/api/user/deck/${deckId}`, { card_ids: cardIds, deck_name: deckName }),

    deleteDeck: (deckId) => apiClient.delete(`/api/user/deck/${deckId}`),

    changeDeckName: (deckId, newName) => apiClient.put(`/api/user/deck/${deckId}/name`, { deck_name: newName }),
  };
};