import React, { useState } from 'react';
import { Link, useNavigate} from 'react-router-dom';

import { useAuth } from '../AuthContext';
import { useAuthService } from '../services/authService';

import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuIcon from '@mui/icons-material/Menu';
import LoginSharpIcon from '@mui/icons-material/LoginSharp';
import LogoutSharpIcon from '@mui/icons-material/LogoutSharp';
import Divider from '@mui/material/Divider';
import GamesSharpIcon from '@mui/icons-material/GamesSharp';
import BuildSharpIcon from '@mui/icons-material/BuildSharp';
import RecentActorsSharpIcon from '@mui/icons-material/RecentActorsSharp';
import GavelSharpIcon from '@mui/icons-material/GavelSharp';
import ScienceSharpIcon from '@mui/icons-material/ScienceSharp';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

const MenuWrapper = () => {
  const { user, logout, isLoading } = useAuth();

  if (isLoading) {
    return <div>Loading...</div>; // Or any loading indicator
  }

  return <Menu key={user ? user.username : 'guest'} user={user} logout={logout} isLoading={isLoading} />;
};

const Menu = ({ user, logout }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const authService = useAuthService();
  const navigate = useNavigate();

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) return;
    setIsDrawerOpen(open);
  };

  const handleLogout = async () => {
    setIsLoading(true);
    try {
      await authService.logout();
      logout();
      navigate('/home');
    } catch (error) {
      console.error('Logout failed', error);
      // Even if the server request fails, we should still log out the user locally
      logout();
      navigate('/home');
    } finally {
      setIsLoading(false);
    }
  };

  const menuItems = [
    { text: 'Spielen', path: '/spielen', icon: <GamesSharpIcon /> },
    { text: 'Mein Deck', path: '/meindeck', icon: <RecentActorsSharpIcon /> },
    { text: 'Testziehen', path: '/testziehen', icon: <ScienceSharpIcon /> },
    { text: 'Regeln', path: '/regeln', icon: <GavelSharpIcon /> },
  ];

  const adminMenuItem = user && user.is_admin ? [
    { text: 'Admin Portal', path: '/admin', icon: <AdminPanelSettingsIcon />, is_admin: true },
  ] : [];

  const commonStyles = {
    backgroundColor: '#212121',
    color: 'white',
    fontFamily: 'monospace',
    fontSize: '18px',
  };

  const list = (
    <Box
      sx={{
        ...commonStyles,
        height: '100%',
        padding: '16px',
      }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <Box sx={{ marginBottom: '8px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        {user ? (
          <>
            <Box sx={{ marginBottom: '5px', marginTop: '5px' }}>Hi, {user.username}</Box>
            <Divider sx={{ backgroundColor: 'white', height: '1px', width: '100%', margin: '16px 0' }} />
            <div 
              onClick={handleLogout}
              style={{
                ...commonStyles,
                textDecoration: 'none',
                display: 'inline-block',
                padding: '8px 16px',
                border: '1px solid white',
                borderRadius: '4px',
                textAlign: 'center',
                backgroundColor: '#212121',
                pointerEvents: isLoading ? 'none' : 'auto', 
              }}
              onMouseEnter={(e) => e.target.style.backgroundColor = 'rgba(255, 255, 255, 0.1)'}
              onMouseLeave={(e) => e.target.style.backgroundColor = 'transparent'}
            >
              <span style={{ display: 'flex', alignItems: 'center' }}>
                {isLoading ? 'Abmelden...' : 'Abmelden'} <LogoutSharpIcon sx={{ marginLeft: 1, fontSize: 'inherit'}} />
              </span>
            </div>
          </>
        ) : (
          <Link 
            to="/login" 
            style={{ 
              ...commonStyles, 
              textDecoration: 'none',
              display: 'inline-block',
              padding: '8px 16px',
              border: '1px solid white',
              borderRadius: '4px',
              textAlign: 'center',
              backgroundColor: '#212121',
            }}
            onMouseEnter={(e) => e.target.style.backgroundColor = 'rgba(255, 255, 255, 0.1)'}
            onMouseLeave={(e) => e.target.style.backgroundColor = 'transparent'}
          >
            <span style={{ display: 'flex', alignItems: 'center' }}>
              Anmelden <LoginSharpIcon sx={{ marginLeft: 1, fontSize: 'inherit' }} />
            </span>
          </Link>
        )}
      </Box>
      <Divider sx={{ backgroundColor: 'white', height: '1px', width: '100%', margin: '16px 0' }} />
      <List sx={{ listStyle: 'none', margin: 0, padding: 0, position: 'relative' }}>
  {menuItems.map((item) => (
    <ListItem key={item.text} disablePadding sx={{ marginBottom: '4px' }}>
      <ListItemButton 
        component={Link} 
        to={item.path}
        sx={{
          paddingLeft: '16px',
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
          },
        }}
      >
        <ListItemIcon sx={{ color: 'white' }}>
          {item.icon}
        </ListItemIcon>
        <ListItemText 
          primary={item.text} 
          primaryTypographyProps={{ 
            fontFamily: 'monospace',
            fontSize: '18px',
          }} 
        />
      </ListItemButton>
    </ListItem>
  ))}
  {adminMenuItem.length > 0 && (
    <>
      <Divider sx={{ backgroundColor: 'white', height: '1px', width: '100%', margin: '16px 0' }} />
      <ListItem disablePadding sx={{ marginBottom: '4px', marginTop: '16px' }}>
        <ListItemButton 
          component={Link} 
          to={adminMenuItem[0].path}
          sx={{
            paddingLeft: '16px',
            '&:hover': {
              backgroundColor: 'rgba(255, 255, 255, 0.1)',
            },
            backgroundColor: 'rgba(255, 255, 255, 0.05)',
            borderRadius: '4px',
          }}
        >
          <ListItemIcon sx={{ color: 'white' }}>
            {adminMenuItem[0].icon}
          </ListItemIcon>
          <ListItemText 
            primary={adminMenuItem[0].text} 
            primaryTypographyProps={{ 
              fontFamily: 'monospace',
              fontSize: '18px',
              fontWeight: 'bold',
            }} 
          />
        </ListItemButton>
      </ListItem>
    </>
  )}
</List>
<Box sx={{ position: 'absolute', bottom: '16px', left: '16px', width: 'calc(100% - 32px)' }}>
  <ListItem disablePadding>
    <ListItemButton 
      component={Link} 
      to="/about"
      sx={{
        paddingLeft: '16px',
        '&:hover': {
          backgroundColor: 'rgba(255, 255, 255, 0.1)',
        },
        justifyContent: 'center',
      }}
    >
      <ListItemText 
        primary="Über Facettenschach" 
        primaryTypographyProps={{ 
          fontFamily: 'monospace',
          fontSize: '14px',
          textAlign: 'center',
        }} 
      />
    </ListItemButton>
  </ListItem>
</Box>
    </Box>
  );

  return (
    <div>
      <Box
        sx={{
          ...commonStyles,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '0 16px',
          height: '64px',
          borderBottom: '1px solid white',
        }}
      >
        <Link to="/Home" style={{ textDecoration: 'none' }}>
          <div style={{ 
            display: 'flex', 
            alignItems: 'center' 
          }}>
            <img 
              src="/logo192.png" 
              alt="Logo" 
              style={{
                width: '40px', // Adjust size as needed
                height: '40px', // Adjust size as needed
                marginRight: '10px' // Space between the image and text
              }} 
            />
            <h1 style={{ 
              margin: 0, 
              color: 'white', 
              fontFamily: 'monospace', 
              fontSize: '20px', 
              cursor: 'pointer'
            }}>
              Facettenschach
            </h1>
          </div>
        </Link>
        <IconButton
          onClick={toggleDrawer(true)}
          sx={{
            color: 'white',
            padding: '8px',
            '&:hover': {
              backgroundColor: 'rgba(255, 255, 255, 0.1)',
            },
          }}
        >
          <MenuIcon />
        </IconButton>
      </Box>
      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={toggleDrawer(false)}
        PaperProps={{
          sx: {
            ...commonStyles,
            width: '65%',
            borderLeft: '1px solid white',
          },
        }}
      >
        {list}
      </Drawer>
    </div>
  );
};

export default MenuWrapper;
