import React from 'react';
import { Navigate } from 'react-router-dom';

import { useAuth } from './AuthContext';


// Components
const AdminRoute = ({ children }) => {
  const { user, loading } = useAuth();

  if (loading) {
    return <div>Loading...</div>;
  }

  return user && user.is_admin ? children : <Navigate to="/home" />;
};

export default AdminRoute;