import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const ExternalLink = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.state && location.state.url) {
      window.open(location.state.url, '_blank', 'noopener,noreferrer');
      navigate(-1);
    }
  }, [location, navigate]);

  return null;
};

export default ExternalLink;